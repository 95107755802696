export const maritalStatus = [
  {
    MaritalStatusId: 1,
    Name: "Single",
  },
  {
    MaritalStatusId: 2,
    Name: "Married",
  },
  {
    MaritalStatusId: 3,
    Name: "Divorced",
  },
  {
    MaritalStatusId: 4,
    Name: "Widowed",
  },
  {
    MaritalStatusId: 5,
    Name: "Seperated",
  },
];
