export const titles = [
  {
    TitleId: 1,
    Name: "Mr.",
  },
  {
    TitleId: 2,
    Name: "Miss.",
  },
  {
    TitleId: 3,
    Name: "Mrs.",
  },
];
