export const nigerianStates = [
  {
    StateId: 1,
    Name: "Abia State",
  },
  {
    StateId: 2,
    Name: "Adamawa State",
  },
  {
    StateId: 3,
    Name: "Akwa Ibom State",
  },
  {
    StateId: 4,
    Name: "Anambra State",
  },
  {
    StateId: 5,
    Name: "Bauchi State",
  },
  {
    StateId: 6,
    Name: "Bayelsa State",
  },
  {
    StateId: 7,
    Name: "Benue State",
  },
  {
    StateId: 8,
    Name: "Borno State",
  },
  {
    StateId: 9,
    Name: "Cross River State",
  },
  {
    StateId: 10,
    Name: "Delta State",
  },
  {
    StateId: 11,
    Name: "Ebonyi State",
  },
  {
    StateId: 12,
    Name: "Edo State",
  },
  {
    StateId: 13,
    Name: "Ekiti State",
  },
  {
    StateId: 14,
    Name: "Enugu State",
  },
  {
    StateId: 15,
    Name: "FCT",
  },
  {
    StateId: 16,
    Name: "Gombe State",
  },
  {
    StateId: 17,
    Name: "Imo State",
  },
  {
    StateId: 18,
    Name: "Jigawa State",
  },
  {
    StateId: 19,
    Name: "Kaduna State",
  },
  {
    StateId: 20,
    Name: "Kano State",
  },
  {
    StateId: 21,
    Name: "Katsina State",
  },
  {
    StateId: 22,
    Name: "Kebbi State",
  },
  {
    StateId: 23,
    Name: "Kogi State",
  },
  {
    StateId: 24,
    Name: "Kwara State",
  },
  {
    StateId: 25,
    Name: "Lagos State",
  },
  {
    StateId: 26,
    Name: "Nasarawa State",
  },
  {
    StateId: 27,
    Name: "Niger State",
  },
  {
    StateId: 28,
    Name: "Ogun State",
  },
  {
    StateId: 29,
    Name: "Ondo State",
  },
  {
    StateId: 30,
    Name: "Osun State",
  },
  {
    StateId: 31,
    Name: "Oyo State",
  },
  {
    StateId: 32,
    Name: "Plateau State",
  },
  {
    StateId: 33,
    Name: "Rivers State",
  },
  {
    StateId: 34,
    Name: "Sokoto State",
  },
  {
    StateId: 35,
    Name: "Taraba State",
  },
  {
    StateId: 36,
    Name: "Yobe State",
  },
  {
    StateId: 37,
    Name: "Zamfara State",
  },
];
