export const genders = [
  {
    GenderId: 1,
    Name: "Male",
  },
  {
    GenderId: 2,
    Name: "Female",
  },
];
